@import '@angular/cdk/overlay-prebuilt.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "~@angular/cdk/overlay-prebuilt.css";

.material-icons {
  font-family: 'Material Icons' !important;
}

/* Mat Dialogue */



.ag-theme-alpine {
  --ag-background-color: #ddd;
}
.ag-theme-alpine .ag-header-cell-label {
  font-style: italic;
}


.mat-bottom-sheet-container {
  position:absolute !important;
  top:45px !important;
  left:80px !important;
  min-height: 750px !important;
  min-width: 1000px !important;
  margin-left:80px !important;
  margin-top: 0px !important;
}


/* Styling Mat Tab */
/* .mat-tab-list .mat-tab-labels .mat-tab-label-active {
  color:red !important;
  background-color: green !important;
  } */

/* 
 .mdc-tab--active {
  background-color: #0b6623 !important;
  color:white !important; 
  border-radius: 20px !important;
  margin-top:5px!important;
  height: 35px!important;
} */

/* .mdc-tab--active :not{
  background-color:dimgray !important;
  border-radius: 20px !important;
  margin-top:5px!important;
  height: 35px!important;
} */

/* .mat-icon{
  fill: white !important;
} */

/* 
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0b6623;
} */

.right-aligned-header > .mat-content {
    justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 0 0 auto;
}

/* Disable Body Scroller */
body::-webkit-scrollbar{
  display: none;
}

/* Expansion Panel */

/* .mat-expansion-panel-spacing{
  margin-top: -10px !important;
}

.mat-expansion-panel-body {
  margin-left: -25px;
  padding-left: 0px;
  margin-right: -20px;
  padding-right: 0px;

} */

.mat-expansion-indicator{
  color: white !important;
}


/* Mat Select */

.mat-mdc-select-panel {
    
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  border: 1px solid rgba(255, 255, 255, 255);
  font-family: 'Verdana', sans-serif; 
  font-size: 12px; 
  font-weight: bold; 
}

/* Mat Slider Style */
/* .mdc-slider--discrete .mdc-slider__thumb{
    margin-top: 5px;
    width: 100px !important;
    height: 35px !important;
    border-radius: 25px;
  
    background: rgb(248, 248, 248);
    border: 2px solid rgb(5, 101, 5);
  }
   */
  /* .mdc-slider__thumb-knob {
  
    display: none !important;
    opacity: 0 !important;
    background-color: white !important;
  
  }
   */
  /* .mat-mdc-slider-visual-thumb .mat-ripple{
    display: none !important;
  }
   */
  /* .mdc-slider__value-indicator{
  
    width: 5px !important;
    background-color:whitesmoke !important;
    color:rgb(0, 0, 1) !important;
    transform: scale(2, 0.6) !important;
    position: absolute !important;
    margin-top: 5px !important;
    margin-left:-28px !important;
    padding-left: 0px !important;
    font-size: 4px !important;
  } */
  
  /* .mat-mdc-slider .mdc-slider__value-indicator-text {
    width: 15px !important;
    padding-left: 0px !important;
  } */
  
  /* .mdc-slider__value-indicator::before{
    display: none !important;
  }
   */

  /* .mat-mdc-slider .mdc-slider__tick-marks {
    background-color:rgba(31, 199, 9, 0.488)!important;
  }  */

/* NGX-SLIDER */

/* .ngx-slider-pointer {
    content: 'Hello' !important;
    background-color:darkslategrey!important;
    height: 16px !important;
    border-radius: 10px!important;
  
  } */
/* 
.ngx-slider-pointer {

    top: auto!important; 
    bottom: 10px!important;
    height: 22px !important;
    width : 50px !important;
    border-radius: 10px!important;
    background-color: #0b6623!important;

  }

.ngx-slider-pointer:after {

    display: contents !important;

    position:absolute!important;
    margin-left: 2px !important;



    content: 'Opacity'!important;
    color:white!important;
  }

  .ngx-slider-model-value{
    font-size: 12px!important;
    font-weight: bold!important;
    top: 2px !important;
  } 

.ngx-slider .ngx-slider-selection-bar {

    opacity: 1!important; 
    left:10px!important;
    visibility: visible!important; 
    transform: rotate(0deg)!important;
  }
  
  .ngx-slider-selection {
    background-color:#0b6623!important;
} */



  /* .custom-slider .ngx-slider .ngx-slider-tick{
    width: 5px !important;
  }

  .custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected{
    background: orange!important;
    width: 5px !important;
  }

  .ngx-slider-active::after {
    background-color:aqua!important;
  } */

  /* Slide toggle */


  /* .mdc-switch .mdc-switch--selected */
  /* .mdc-switch--checked{
    background-color: red!important;
    content: 'Yes'!important;
  } */

  .mat-mdc-slide-toggle{
    height:20px !important;
    width: 300px !important;
    /* background-color: #0b6623 !important; */
  }
  .mdc-switch{
    height: 20px !important;
    width: 300px !important;

  }

  .mdc-switch__track{
    /* background-color: #0b6623 !important; */
    height: 20px !important;
    width: 300px !important;
  }

  .mdc-switch__track::before{
    
    background-color: var(--afterColor,green) !important;
    content: var(--beforeContect,'Climate Projection') !important;
  }

  .mdc-switch__track::after{
    background-color: var(--afterColor,blue) !important;
    content: var(--afterContect,'Climate Risk Indices') !important;
  }


  /* .mat-mdc-mini-fab:hover { background-color: orange !important; }

  .mat-mdc-mini-fab:focus { background-color: orange !important; color: gray !important; } */

  .mat-button-toggle{
    /* border-radius: 1px !important; */
    background-color:aliceblue !important;
  }
  .mat-button-toggle:hover { background-color: orange !important; }

  .mat-button-toggle:active {background-color: orange !important;}

  .mat-button-toggle-checked{
    background-color:#0b6623 !important;
    color:white !important;
  }